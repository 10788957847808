var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, _vm._l(_vm.brands, function (brand, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "2"
      }
    }, [_c('v-card', {
      attrs: {
        "color": "grey lighten-5",
        "height": "100%"
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1,
        "content-class": "d-flex flex-column justify-space-between"
      }
    }, [_c('div', {
      staticClass: "pa-16px pa-md-20px"
    }, [_c('div', {
      staticClass: "txt txt--sm font-weight-bold"
    }, [_vm._v(" " + _vm._s(index + 1) + " ")])]), _c('div', [_c('v-img', {
      staticClass: "mx-auto",
      attrs: {
        "aspect-ratio": 210 / 88,
        "src": brand.logo,
        "alt": brand.title
      }
    })], 1), _c('div', {
      staticClass: "pa-16px pa-md-20px text-center"
    }, [_c('div', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(brand.country) + " ")])])])], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }