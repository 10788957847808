<template>
    <div class="mt-20px mt-md-40px">
        <v-row>
            <v-col v-for="(legacy, index) in legacies" :key="index" cols="6" lg="3">
                <v-card :color="legacy.active ? 'grey darken-4' : 'grey lighten-5'" height="100%">
                    <v-responsive :aspect-ratio="1 / 1">
                        <div class="pa-16px pa-md-20px">
                            <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black letter-spacing-n02" :class="legacy.active ? 'text-outlined-white' : 'text-outlined-dark'">
                                {{ legacy.year }}
                            </div>
                        </div>
                        <div class="pa-16px pa-md-20px">
                            <v-row class="row--xs">
                                <v-col v-for="(item, index) in legacy.list" :key="index" cols="12">
                                    <p class="txt txt--sm" :class="legacy.active ? 'white--text' : ''">
                                        {{ item }}
                                    </p>
                                </v-col>
                            </v-row>
                        </div>
                    </v-responsive>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            legacies: [
                {
                    year: "2001",
                    list: [
                        "주식회사 조이포라이프 설립",
                        //
                    ],
                },
                {
                    year: "2007",
                    list: [
                        "ISO 9001 인증",
                        "ISO 14001 인증",
                        //
                    ],
                },
                {
                    year: "2008",
                    list: [
                        "GIO 샤워시스템 Good Design 획득",
                        "GIO 샤워시스템 개발",
                        "LOSH 샤워 50종",
                        "기업부설연구소 설립",
                        //
                    ],
                },
                {
                    year: "2009",
                    list: [
                        "기업부설연구소 디자인 개발실 인증",
                        "cUPC인증(미국)",
                        "TOTO 수출계약",
                        "미국 애틀란타 KBIS전시회 출품",
                        //
                    ],
                },
                {
                    year: "2010",
                    list: [
                        "Sililor Laufen에 rain shower head 공급사로 지정",
                        "세계 최초 Full touch shower System T-shower 출시",
                        "미국 시카고 KBIS전시회 참가",
                        //
                    ],
                },
                {
                    year: "2011",
                    list: [
                        "상하이 KBC 최초 참가",
                        "독일 ISH 최초 참가",
                        "창립 10주년 기념",
                        "유망 중소기업 인증",
                        "업체별 원산지인증 수출자 인증",
                        "IF 디자인어워드 T-Shower and GIO® 수상",
                        //
                    ],
                },
                {
                    year: "2012",
                    list: [
                        "벤쳐기업인증",
                        "도곡동 쇼룸 오픈",
                        "KOHLER 한국 대리점 계약",
                        //
                    ],
                },
                {
                    year: "2013",
                    list: [
                        "제주 헬스케어타운 욕실부문 턴키납품(1차)",
                        "3백만불 수출탑 수상",
                        "KC 인증 획득",
                        //
                    ],
                },
                {
                    year: "2014",
                    list: [
                        "제주 헬스케어타운 욕실부문 턴키납품(2차)",
                        "제2공장 설립 (자동화라인 1호기 설치)",
                        //
                    ],
                },
                {
                    year: "2015",
                    list: [
                        "5T 샤워 디자인, 개발 완료",
                        "화성 사옥, 공장 이전",
                        "중국 KBIS전시회 개최",
                        "인도 JAQUAR 11억 유상증자",
                        //
                    ],
                },
                {
                    year: "2016",
                    list: [
                        "뿌리기업 인증 획득",
                        "KIMES(의료기) 전시회 개최",
                        "글로벌 강소기업 인증 취득",
                        "5백만불 수출탑 수상",
                        "제2공장 자동도금화라인 2호기 증설",
                        //
                    ],
                },
                {
                    year: "2017",
                    list: [
                        "Kohler Kitchen 런칭",
                        "6.5억 유상증자",
                        "글로벌 강소기업 인증",
                        "지사설립(프랑크푸르트)",
                        //
                    ],
                },
                {
                    year: "2018",
                    list: [
                        "콜러 주방 가구 런칭",
                        "유망중소기업 인증취득",
                        "부산 LCT LED-SHOWER 납품",
                        "미아 9-1 재건축 아파트 주방가구 및 욕실부분 턴키 납품",
                        "LUMOS LED 샤워헤드 신제품 개발",
                        //
                    ],
                },
                {
                    year: "2019",
                    list: [
                        "독일 ISH 최초 참가",
                        "120억 미아 9-1 재건축아파트 주방가구 및 욕실부분 턴키 납품",
                        "90억 제주드림타워 재건축 아파트 욕실부분 턴키 납품",
                        //
                    ],
                },
                {
                    year: "2020",
                    list: [
                        "개포4단지 콜러욕실 110억 수주",
                        //
                    ],
                },
                {
                    year: "2021",
                    list: [
                        "Aran world의 (Denovo cucine)런칭",
                        "부산 협성마리나 콜러주방 918세대",
                        //
                    ],
                },
                {
                    year: "2022",
                    list: [
                        "제주 포레팰리스 타운하우스 계약",
                        "광주 생타드레스 Denovo, Newform 설치",
                        "주안 파크자이, 흑석리버 파크자이, 개포프레지던트 자이 욕실용품 납품",
                        //
                    ],
                },
                {
                    year: "2023",
                    list: [
                        "미성크로바 1861세대 계약",
                        "북서울 자이 미아 3555세대 납품",
                        "온라인공식몰 오픈",
                        "엔젤 리프트 체어 런칭",
                        "천만불 수출탑 수상",
                        //
                    ],
                },
                {
                    year: "2024",
                    list: [
                        "에어로차지 레인샤워헤드 출시",
                        "빌레로이앤보흐 강남 쇼룸 오픈",
                        "빌레로이앤보흐 국내 런칭",
                        //
                    ],
                    active: true,
                },
                {
                    year: "2025",
                    // list: [
                    //     "",
                    //     //
                    // ],
                },
            ],
        };
    },
};
</script>