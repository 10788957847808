<template>
    <div class="mt-20px mt-md-40px">
        <v-row class="row--sm">
            <v-col v-for="(brand, index) in brands" :key="index" cols="6" md="4" lg="2">
                <v-card color="grey lighten-5" height="100%">
                    <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                        <div class="pa-16px pa-md-20px">
                            <div class="txt txt--sm font-weight-bold">
                                {{ index + 1 }}
                            </div>
                        </div>
                        <div>
                            <v-img :aspect-ratio="210 / 88" :src="brand.logo" :alt="brand.title" class="mx-auto" />
                        </div>
                        <div class="pa-16px pa-md-20px text-center">
                            <div class="txt txt--sm">
                                {{ brand.country }}
                            </div>
                        </div>
                    </v-responsive>
                </v-card>
            </v-col>

        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            brands: [
                {
                    title: "KOHLER",
                    country: "China/USA",
                    logo: "/images/sub/about/about-logo.png",
                },
                {
                    title: "KALDEWEI",
                    country: "Germany",
                    logo: "/images/sub/about/about-logo2.png",
                },
                {
                    title: "ISVEA",
                    country: "Italy/Turkey",
                    logo: "/images/sub/about/about-logo3.png",
                },
                {
                    title: "Jaguar",
                    country: "India",
                    logo: "/images/sub/about/about-logo4.png",
                },
                {
                    title: "EASO",
                    country: "China",
                    logo: "/images/sub/about/about-logo5.png",
                },
                {
                    title: "paffoni",
                    country: "Italy",
                    logo: "/images/sub/about/about-logo6.png",
                },
                {
                    title: "FLOVA",
                    country: "China",
                    logo: "/images/sub/about/about-logo7.png",
                },
                {
                    title: "HAVEN",
                    country: "Sweden",
                    logo: "/images/sub/about/about-logo8.png",
                },
                {
                    title: "THG",
                    country: "France",
                    logo: "/images/sub/about/about-logo9.png",
                },
                {
                    title: "IB RUBINETTI",
                    country: "Italy",
                    logo: "/images/sub/about/about-logo10.png",
                },
                {
                    title: "LANGBERGER",
                    country: "Germany",
                    logo: "/images/sub/about/about-logo11.png",
                },
                {
                    title: "SCARABEO",
                    country: "Italy",
                    logo: "/images/sub/about/about-logo12.png",
                },
                {
                    title: "DORNBRACHT",
                    country: "Germany",
                    logo: "/images/sub/about/about-logo13.png",
                },
                {
                    title: "ALISEO",
                    country: "Germany",
                    logo: "/images/sub/about/about-logo14.png",
                },
                {
                    title: "viega",
                    country: "Germany",
                    logo: "/images/sub/about/about-logo15.png",
                },
                {
                    title: "KA",
                    country: "China",
                    logo: "/images/sub/about/about-logo16.png",
                },
                {
                    title: "DECOR WALTHER",
                    country: "Germany",
                    logo: "/images/sub/about/about-logo17.png",
                },
                {
                    title: "ZUCCHETTI",
                    country: "Germany",
                    logo: "/images/sub/about/about-logo18.png",
                },
                {
                    title: "ORTONBATH",
                    country: "China",
                    logo: "/images/sub/about/about-logo19.png",
                },
                {
                    title: "OCEANWELL",
                    country: "China",
                    logo: "/images/sub/about/about-logo23.png",
                },
                {
                    title: "Villeroy & Boch",
                    country: "Germany",
                    logo: "/images/sub/about/about-logo25.png",
                },
            ],
        };
    },
};
</script>