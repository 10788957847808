var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-row', _vm._l(_vm.legacies, function (legacy, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "lg": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "color": legacy.active ? 'grey darken-4' : 'grey lighten-5',
        "height": "100%"
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('div', {
      staticClass: "pa-16px pa-md-20px"
    }, [_c('div', {
      staticClass: "font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black letter-spacing-n02",
      class: legacy.active ? 'text-outlined-white' : 'text-outlined-dark'
    }, [_vm._v(" " + _vm._s(legacy.year) + " ")])]), _c('div', {
      staticClass: "pa-16px pa-md-20px"
    }, [_c('v-row', {
      staticClass: "row--xs"
    }, _vm._l(legacy.list, function (item, index) {
      return _c('v-col', {
        key: index,
        attrs: {
          "cols": "12"
        }
      }, [_c('p', {
        staticClass: "txt txt--sm",
        class: legacy.active ? 'white--text' : ''
      }, [_vm._v(" " + _vm._s(item) + " ")])]);
    }), 1)], 1)])], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }